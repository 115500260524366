import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Component, OnInit, Inject } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-alert-dialog',
  templateUrl: './alert-dialog.html',
  styleUrls: ['./alert-dialog.scss']
})
export class AlertDialogComponent implements OnInit {
  title: string;
  message: string;
  subTitle: string;
  showButton: boolean;
  buttonMessage: string;
  theme : string;

  constructor(
    public dialogRef: MatDialogRef<AlertDialogComponent>,
    private router: Router,
    @Inject(MAT_DIALOG_DATA) public data: AlertDialogModel
    ) {
    // Update view with given values
    this.title = data.title;
    this.subTitle = data.subTitle;
    this.message = data.message;
    this.showButton = data.showButton;
    this.buttonMessage = data.buttonMessage ? data.buttonMessage : "Rinnova Ora";
    this.theme = data.theme ? data.theme : "red";
  }

  ngOnInit() {
  }

  onConfirm(): void {
    // Close the dialog, return true
    this.dialogRef.close(true);
  }

  onDismiss(): void {
    // Close the dialog, return false
    this.dialogRef.close(false);
  }

  goToAccount() {
    this.router.navigate(['/account']);
    this.dialogRef.close(true);
  }
}

/**
 * Class to represent confirm dialog model.
 *
 * It has been kept here to keep it as part of shared component.
 */
export class AlertDialogModel {

  constructor(public title: string, public subTitle: string, public message: string = "Confirm", public showButton: boolean = false, public buttonMessage?: string, public theme?: string) {
  }
}
