<div [ngClass]="theme == 'blue' ? 'wrapper center theme-blue' : 'wrapper center'">

  <div class="icon" (click)="onConfirm()">
    <svg xmlns="http://www.w3.org/2000/svg" width="22" height="22" viewBox="0 0 16 16" fill="none">
      <path d="M4.36792 12.4739L12.4739 4.36792L13.6319 5.52592L5.52592 13.6319L4.36792 12.4739Z" fill="white"/>
      <path d="M5.52592 4.36792L13.6319 12.4739L12.4739 13.6319L4.36792 5.52592L5.52592 4.36792Z" fill="white"/>
    </svg>
  </div>

  <h3 mat-dialog-title>
    {{title}}
  </h3>

  <div mat-dialog-content>
    <p class="sub-title">{{subTitle}}</p>
  </div>
  
  <div mat-dialog-content>
    <p>{{message}}</p>
  </div>

  <div mat-dialog-actions class="buttons-container">
    <button mat-raised-button color="primary" id="no" (click)="goToAccount()" *ngIf="showButton" [ngClass]="theme == 'blue' ? 'theme-blue-btn' : ''">{{buttonMessage}}</button>
  </div>

</div>