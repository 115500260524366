import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HTTP_INTERCEPTORS } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { BrowserModule } from '@angular/platform-browser';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppInitService } from './services/app-init.service';
import { AuthenticationService } from './services/authentication.service';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSnackBar, MatSnackBarModule } from '@angular/material/snack-bar';
import { AppbarModule } from './components/appbar/appbar.module';
import { Router } from '@angular/router';
import { NgxIndexedDBModule } from 'ngx-indexed-db';
import { tt01DBConfig } from './idb.config';
import { CommonModule, registerLocaleData } from '@angular/common';
import localeIt from '@angular/common/locales/it';
import { MatDialogModule } from '@angular/material/dialog';
import { MatButtonModule } from '@angular/material/button';
import { MatInputModule } from '@angular/material/input';
import { AlertDialogModule } from './components/alert-dialog/alert-dialog.module';

export function initApp(appInitService: AppInitService) {
    return (): Promise<any> => {
        return appInitService.init();
    }
}

registerLocaleData(localeIt);

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    
    constructor(
        private authenticationService: AuthenticationService,
        private snackBar: MatSnackBar,
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(catchError(err => {
            
            if ([401].includes(err.status) && this.router.url.split("?")[0] != '/login') {
                // auto logout if 401
                this.authenticationService.logout(true);
            }

            if ([429].includes(err.status)) {
                // too many requests
                this.snackBar.open("Spicente troppe richieste, Riprovare più tardi.", 'Close', {
                    duration: 3000,
                    // verticalPosition: 'top',
                    panelClass: 'generic-notification'
                });
            }

            if ([0].includes(err.status)) {                
                this.snackBar.open("Spiacente si è verificato un errore. Riprovare più tardi.", 'Close', {
                    duration: 3000,
                    // verticalPosition: 'top',
                    panelClass: 'generic-notification'
                });
            }

            return throwError(() => err);
        }))
    }
}

@NgModule({
    declarations: [
        AppComponent,
    ],
    imports: [
        AlertDialogModule,
        BrowserModule,
        AppRoutingModule,
        HttpClientModule,
        BrowserAnimationsModule,
        MatSnackBarModule,
        MatDialogModule,
        NgxIndexedDBModule.forRoot(tt01DBConfig),
    ],
    providers: [
        {
            provide: APP_INITIALIZER,
            useFactory: initApp,
            deps: [AppInitService],
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: ErrorInterceptor,
            multi: true
        },
        {
            provide: LOCALE_ID,
            useValue: "it-IT" // forced IT locale
        },
        HttpClient
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }
