import { DBConfig } from "ngx-indexed-db";
import { environment } from "src/environments/environment";


export const tt01DBConfig: DBConfig = {
    name: "tt01",
    version: environment.tt01DBVersion,
    objectStoresMeta: [{
        store: "imageAssets",
        storeConfig: {
            keyPath: "uuid",
            autoIncrement: false
        },
        storeSchema: [
            {
                name: 'uuid',
                keypath: 'uuid',
                options: { unique: true }
            },
            {
                name: 'name',
                keypath: 'name',
                options: { unique: false }
            },
            {
                name: 'type',
                keypath: 'type',
                options: { unique: false }
            },
            {
                name: 'title',
                keypath: 'title',
                options: { unique: false }
            },
            {
                name: 'link',
                keypath: 'link',
                options: { unique: false }
            },
            {
                name: 'b64',
                keypath: 'b64',
                options: { unique: false }
            },
        ]
    }]
};